import React from "react"
import Loadable from "react-loadable"



import style from "./cadastro.module.css"
import { geolocated } from "react-geolocated"
//import { GoogleMap, LoadScript } from "@react-google-maps/api"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import brand from "../img/logo-brand.png"

import ReactPixel from "react-facebook-pixel";


const LoadbleFormCadCli = Loadable({
  loader: () => import("../components/formCadCli/index"),
  loading(){
    return <div>loading...</div>
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3e490f",
    },
  },
})

class Cadastro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      center: {
        lat: -11.5649986,
        lng: -39.2905602,
      },
      zoom: 10,
    }
  }

  componentDidMount() {
    ReactPixel.fbq('track','PageViewCadastro');
  }



  render() {
    /*isGeolocationAvailable, isGeolocationEnabled */
    const { coords } = this.props
    let latitude = this.state.center.lat
    let longitude = this.state.center.lng
    if (coords) {
      latitude = Number(coords["latitude"].toFixed(7))
      longitude = Number(coords["longitude"].toFixed(7))
    }
    return (
      <ThemeProvider theme={theme}>
        <div className={style.containerMain}>
          <div className={style.containImgBg}></div>

          <div className={style.containerContain}>
            <div className={style.containerForm}>
              <img src={brand} alt="akisuafeira" className={style.brand}></img>

              <LoadbleFormCadCli latitude={latitude} longitude={longitude}></LoadbleFormCadCli>
            </div>
          </div>
        </div>
      </ThemeProvider>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(Cadastro)
